<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0249023 17.975V6L7.9999 0L15.9749 6V17.975H9.6999V11.2H6.2999V17.975H0.0249023ZM2.1749 15.825H4.1499V9.05H11.8499V15.825H13.8249V7.05L7.9999 2.7L2.1749 7.05V15.825Z" />
  </svg>
</template>
<script>
export default {
  name: 'icon-home',
  // props: {
  //   fill_color: {
  //     type: String,
  //     default: '#000'
  //   }
  // }
  // :fill="fill_color"
}
</script>